import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation, useParams } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import DSGuard from '../guards/DSGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { OPENAPI } from '../config'; // useEffect(() => {

// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { ds } = useParams();
  let dataStore;

  if (OPENAPI.usePlatform) {
    dataStore = ds;
  } else {
    dataStore = OPENAPI.defaultDataStore;
  }

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },

        { path: 'dataStore', element: <DataStore /> },
        { path: 'login', element: <Login /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard/:ds',
      element: (
        <AuthGuard>
          <DSGuard>
            <DashboardLayout />
          </DSGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="log/list" replace />, index: true },
        {
          path: 'api-key',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <ApiKeyList /> },
            { path: ':id', element: <ApiKeyDetails /> },
            { path: ':id/edit', element: <ApiKeyEdit /> },
            { path: 'new', element: <ApiKeyCreate /> },
          ],
        },
        {
          path: 'log',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <LogList /> },
            { path: ':id', element: <LogDetails /> },
            { path: ':id/edit', element: <LogEdit /> },
            { path: 'new', element: <LogCreate /> },
          ],
        },

        {
          path: 'ingestion',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <IngestionList /> },
            { path: ':id', element: <IngestionDetails /> },
            { path: ':id/edit', element: <IngestionEdit /> },
            { path: 'new', element: <IngestionCreate /> },
          ],
        },
        {
          path: 'topic',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <TopicList /> },
            { path: ':id', element: <TopicDetails /> },
            { path: ':id/edit', element: <TopicEdit /> },
            { path: ':id/play', element: <TopicPlay /> },
            { path: 'new', element: <TopicCreate /> },
            { path: ':id/records', element: <TopicRecordList /> },
            { path: ':id/records/new', element: <TopicRecordCreate /> },
            { path: ':id/records/:timestamp', element: <TopicRecordDetails /> },
            // { path: ':id/records/:timestamp/edit', element: <TopicRecordEdit /> },
          ],
        },
        {
          path: 'record',
          children: [{ element: <RecordRedirect />, index: true }],
        },
        {
          path: 'extraction',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <ExtractionList /> },
            { path: ':id', element: <ExtractionDetails /> },
            { path: ':id/edit', element: <ExtractionEdit /> },
            { path: 'new', element: <ExtractionCreate /> },
            { path: ':id/topics', element: <ExtractionTopicList /> },
            { path: ':id/topics/new', element: <ExtractionTopicCreate /> },
            { path: ':id/topics/:topicId', element: <ExtractionTopicDetails /> },
            { path: ':id/topics/:topicId/edit', element: <ExtractionTopicEdit /> },
          ],
        },
        {
          path: 'extraction-topic',
          children: [{ element: <ExtractionTopicRedirect />, index: true }],
        },
        {
          path: 'message-type',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <MessageTypeList /> },
            { path: ':id', element: <MessageTypeDetails /> },
            { path: ':id/edit', element: <MessageTypeEdit /> },
            { path: 'new', element: <MessageTypeCreate /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: ':id', element: <UserDetails /> },
            { path: ':id/edit', element: <UserEdit /> },
            { path: 'new', element: <UserCreate /> },
          ],
        },
        {
          path: 'group',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <GroupList /> },
            { path: ':id', element: <GroupDetails /> },
            { path: ':id/edit', element: <GroupEdit /> },
            { path: 'new', element: <GroupCreate /> },
          ],
        },
        {
          path: 'group-association',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <GroupAssociationList /> },
            { path: ':id', element: <GroupAssociationDetails /> },
            { path: ':id/edit', element: <GroupAssociationEdit /> },
            { path: 'new', element: <GroupAssociationCreate /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ element: <Navigate to={`/dashboard/${dataStore}/log/list`} replace />, index: true }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

const DataStore = Loadable(lazy(() => import('../pages/auth/DataStore')));
const Login = Loadable(lazy(() => import('../pages/auth/Login')));

// DASHBOARD

// REDIRECTS
const ExtractionTopicRedirect = Loadable(lazy(() => import('../components/redirects/ExtractionTopicRedirect')));
const RecordRedirect = Loadable(lazy(() => import('../components/redirects/RecordRedirect')));

// LOGS
const ApiKeyList = Loadable(lazy(() => import('../pages/dashboard/ApiKeyList')));
const ApiKeyDetails = Loadable(lazy(() => import('../pages/dashboard/ApiKeyDetails')));
const ApiKeyCreate = Loadable(lazy(() => import('../pages/dashboard/ApiKeyCreate')));
const ApiKeyEdit = Loadable(lazy(() => import('../pages/dashboard/ApiKeyEdit')));

// LOGS
const LogList = Loadable(lazy(() => import('../pages/dashboard/LogList')));
const LogDetails = Loadable(lazy(() => import('../pages/dashboard/LogDetails')));
const LogCreate = Loadable(lazy(() => import('../pages/dashboard/LogCreate')));
const LogEdit = Loadable(lazy(() => import('../pages/dashboard/LogEdit')));

// INGESTIONS
const IngestionList = Loadable(lazy(() => import('../pages/dashboard/IngestionList')));
const IngestionDetails = Loadable(lazy(() => import('../pages/dashboard/IngestionDetails')));
const IngestionCreate = Loadable(lazy(() => import('../pages/dashboard/IngestionCreate')));
const IngestionEdit = Loadable(lazy(() => import('../pages/dashboard/IngestionEdit')));

// TOPICS
const TopicList = Loadable(lazy(() => import('../pages/dashboard/TopicList')));
const TopicDetails = Loadable(lazy(() => import('../pages/dashboard/TopicDetails')));
const TopicCreate = Loadable(lazy(() => import('../pages/dashboard/TopicCreate')));
const TopicEdit = Loadable(lazy(() => import('../pages/dashboard/TopicEdit')));
const TopicPlay = Loadable(lazy(() => import('../pages/dashboard/TopicPlay')));
const TopicRecordList = Loadable(lazy(() => import('../pages/dashboard/TopicRecordList')));
const TopicRecordCreate = Loadable(lazy(() => import('../pages/dashboard/TopicRecordCreate')));
const TopicRecordDetails = Loadable(lazy(() => import('../pages/dashboard/TopicRecordDetails')));
// const TopicRecordEdit = Loadable(lazy(() => import('../pages/dashboard/TopicRecordEdit')));

// EXTRACTIONS
const ExtractionList = Loadable(lazy(() => import('../pages/dashboard/ExtractionList')));
const ExtractionDetails = Loadable(lazy(() => import('../pages/dashboard/ExtractionDetails')));
const ExtractionCreate = Loadable(lazy(() => import('../pages/dashboard/ExtractionCreate')));
const ExtractionEdit = Loadable(lazy(() => import('../pages/dashboard/ExtractionEdit')));
const ExtractionTopicList = Loadable(lazy(() => import('../pages/dashboard/ExtractionTopicList')));
const ExtractionTopicCreate = Loadable(lazy(() => import('../pages/dashboard/ExtractionTopicCreate')));
const ExtractionTopicDetails = Loadable(lazy(() => import('../pages/dashboard/ExtractionTopicDetails')));
const ExtractionTopicEdit = Loadable(lazy(() => import('../pages/dashboard/ExtractionTopicEdit')));

// MESSAGE TYPES
const MessageTypeList = Loadable(lazy(() => import('../pages/dashboard/MessageTypeList')));
const MessageTypeDetails = Loadable(lazy(() => import('../pages/dashboard/MessageTypeDetails')));
const MessageTypeCreate = Loadable(lazy(() => import('../pages/dashboard/MessageTypeCreate')));
const MessageTypeEdit = Loadable(lazy(() => import('../pages/dashboard/MessageTypeEdit')));

// USER
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserDetails = Loadable(lazy(() => import('../pages/dashboard/UserDetails')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const UserEdit = Loadable(lazy(() => import('../pages/dashboard/UserEdit')));

// GROUP
const GroupList = Loadable(lazy(() => import('../pages/dashboard/GroupList')));
const GroupDetails = Loadable(lazy(() => import('../pages/dashboard/GroupDetails')));
const GroupCreate = Loadable(lazy(() => import('../pages/dashboard/GroupCreate')));
const GroupEdit = Loadable(lazy(() => import('../pages/dashboard/GroupEdit')));

// GROUP ASSOCIATION
const GroupAssociationList = Loadable(lazy(() => import('../pages/dashboard/GroupAssociationList')));
const GroupAssociationDetails = Loadable(lazy(() => import('../pages/dashboard/GroupAssociationDetails')));
const GroupAssociationCreate = Loadable(lazy(() => import('../pages/dashboard/GroupAssociationCreate')));
const GroupAssociationEdit = Loadable(lazy(() => import('../pages/dashboard/GroupAssociationEdit')));

// MAIN
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
