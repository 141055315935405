import { useContext } from 'react';
//
// import { AuthContext } from '../contexts/Auth0Context';
// import { AuthContext } from '../contexts/FirebaseContext';
// import { AuthContext } from '../contexts/AwsCognitoContext';
import { AuthContext } from '../contexts/HostedUIAwsContext';

// ----------------------------------------------------------------------

export default function useDS() {
  const context = useContext(AuthContext);

  return context.dataStore;
}
