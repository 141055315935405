import { useContext, useEffect, useState } from 'react';
import { useOperationMethod } from 'react-openapi-client';
//
// import { AuthContext } from '../contexts/Auth0Context';
// import { AuthContext } from '../contexts/FirebaseContext';
// import { AuthContext } from '../contexts/AwsCognitoContext';
import { AuthContext } from '../contexts/HostedUIAwsContext';
import useAuth from './useAuth';

// ----------------------------------------------------------------------

export default function useUser() {
  const { setUserInfo } = useContext(AuthContext);
  const { idToken } = useAuth();

  const [getMe, { data }] = useOperationMethod('Me-get_me');

  useEffect(() => {
    getMe();
  }, [idToken]);

  const [user, setUser] = useState({ username: null, isAdmin: false });

  useEffect(() => {
    if (data && data.data) {
      setUserInfo(data.data);
      setUser(data.data);
    }
  }, [data]);

  return user;
}
