// @mui
import { useState } from 'react';
import { Stack, Button } from '@mui/material';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_AUTH, PATH_DOCS } from '../../../routes/paths';
import { COGNITO_API, OPENAPI } from '../../../config';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const usePlatform = OPENAPI.usePlatform === 'true';
  const cognitoEnabled = COGNITO_API.cognitoEnabled === 'true';

  const { logout } = useAuth();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const handleChangeDataStore = () => {
    navigate(PATH_AUTH.dataStore);
  };

  return (
    <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
      <Stack spacing={2}>
        <Button href={PATH_DOCS} target="_blank" rel="noopener" variant="contained">
          API Docs
        </Button>
        {cognitoEnabled && (
          <Button onClick={logout} rel="noopener">
            Logout
          </Button>
        )}
        {usePlatform && <Button onClick={handleChangeDataStore}>Change DataStore</Button>}
      </Stack>
    </Stack>
  );
}
