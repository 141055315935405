import PropTypes from 'prop-types';
// @mui
import { Box, Typography } from '@mui/material';
// hooks
// import useAuth from '../../../hooks/useAuth';
import useDS from '../../../hooks/useDS';
// routes

NavbarDS.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarDS({ isCollapse }) {
  const ds = useDS();

  if (ds === null) {
    return null;
  }
  const displayDs = `${ds.toUpperCase()} DataStore`;

  return (
    <Box
      sx={{
        ml: 2,
        transition: (theme) =>
          theme.transitions.create('width', {
            duration: theme.transitions.duration.shorter,
          }),
        ...(isCollapse && {
          ml: 0,
          width: 0,
        }),
      }}
    >
      <Typography variant="h6" noWrap sx={{ color: 'text.secondary' }}>
        {displayDs}
      </Typography>
    </Box>
  );
}
