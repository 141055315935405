import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import NodeCache from 'node-cache';
// utils
import axios from '../utils/axios';
import { decode } from '../utils/decode-jwt';

//
import { COGNITO_API } from '../config';

// ----------------------------------------------------------------------

// CAUTION: User Cognito is slily difference from firebase, so be sure to read the doc carefully.
const lqsCache = new NodeCache({ stdTTL: 60, checkPeriod: 40 });

// export const UserPool = new CognitoUserPool({
//   UserPoolId: COGNITO_API.userPoolId,
//   ClientId: COGNITO_API.clientId,
// });

const cognitoEnabled = COGNITO_API.cognitoEnabled === 'true';
let clientId;
let redirectUri;
let redirectUriEncoded;
let domain;

if (cognitoEnabled) {
  clientId = COGNITO_API.clientId;
  redirectUri = COGNITO_API.redirectUri;
  redirectUriEncoded = COGNITO_API.redirectUriEncoded;
  domain = COGNITO_API.domain;
} else {
  clientId = '';
  redirectUri = '';
  redirectUriEncoded = '';
  domain = '';
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  hasDataStore: false,
  dataStore: null,
  idToken: null,
};

const handlers = {
  AUTHENTICATE: (state, action) => {
    const { isAuthenticated, user, hasDataStore, dataStore, idToken } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      hasDataStore,
      dataStore,
      idToken,
    };
  },
  LOGOUT: (state) =>
    null({
      ...state,
      isAuthenticated: false,
      user: null,
      dataStore: null,
    }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'cognito',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setDataStore: () => Promise.resolve(),
  setUserInfo: () => Promise.resolve(),
  changeDataStore: () => Promise.resolve(),
  idToken: '',
  lqsCache,
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [user, setUser] = useState({ username: '', isAdmin: false });
  const [validToken, setValidToken] = useState(true);
  const [idToken, setIdToken] = useState('');
  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => {
    if (!loggedOut) {
      if (validToken === true) {
        setTimeout(() => {
          setValidToken(false);
        }, 3000000);
      }
    }
  }, [validToken, loggedOut]);
  useEffect(() => {
    if (!loggedOut) {
      if (validToken === false) {
        const refreshToken = window.localStorage.getItem('refresh_token');
        const article = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}`;
        const headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded' };

        axios
          .post(`${domain}/oauth2/token`, article, { headers })
          .then((response) => {
            window.localStorage.setItem('id_token', response.data.id_token);
            window.localStorage.setItem('access_token', response.data.access_token);
            decode(response.data.access_token)
              .then((decoded) => {
                const groups = decoded['cognito:groups'];
                if (groups) {
                  window.localStorage.setItem('groups', JSON.stringify(groups));
                } else {
                  console.log('No groups found: ', decoded);
                }
              })
              .catch((err) => {
                if (err.name === 'TokenExpiredError') {
                  logout();
                }
              });
            setIdToken(response.data.id_token);
          })
          .catch((err) => {
            console.error(err);
            logout();
          });

        if (!loggedOut) {
          setValidToken(true);
        }
      }
    }
  }, [validToken, loggedOut]);

  useEffect(() => {
    const accessToken = window.localStorage.getItem('access_token');
    decode(accessToken)
      .then((decoded) => {
        const groups = decoded['cognito:groups'];
        if (groups) {
          window.localStorage.setItem('groups', JSON.stringify(groups));
        } else {
          console.log('No groups found: ', decoded);
        }
      })
      .catch((err) => {
        if (err.name === 'TokenExpiredError') {
          logout();
        }
      });
    const dataStore = window.localStorage.getItem('lqs_admin_ds');
    if (accessToken !== null && accessToken !== '' && validToken === true) {
      dispatch({
        type: 'AUTHENTICATE',
        payload: {
          isAuthenticated: true,
          hasDataStore: true,
          user,
          dataStore,
          idToken,
        },
      });
    }
  }, [user]);

  const getToken = useCallback(
    () =>
      new Promise(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        params.delete('code');

        if (code !== null) {
          const article = `grant_type=authorization_code&client_id=${clientId}&code=${code}&redirect_uri=${redirectUriEncoded}`;
          const headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded' };

          axios
            .post(`${domain}/oauth2/token`, article, { headers })
            .then((response) => {
              window.localStorage.setItem('id_token', response.data.id_token);
              window.localStorage.setItem('access_token', response.data.access_token);
              window.localStorage.setItem('refresh_token', response.data.refresh_token);
              setValidToken(true);
              decode(response.data.access_token)
                .then((decoded) => {
                  const groups = decoded['cognito:groups'];
                  if (groups) {
                    window.localStorage.setItem('groups', JSON.stringify(groups));
                  } else {
                    console.log('No groups found: ', decoded);
                  }
                })
                .catch((err) => {
                  if (err.name === 'TokenExpiredError') {
                    logout();
                  }
                });

              setIdToken(response.data.id_token);

              window.location.replace(window.location.pathname);
            })
            .catch((err) => {
              console.log(err);
              dispatch({
                type: 'AUTHENTICATE',
                payload: {
                  isAuthenticated: false,
                  hasDataStore: false,
                  user: null,
                  dataStore: null,
                  idToken,
                },
              });
            });
        } else if (
          window.localStorage.getItem('access_token') === null ||
          window.localStorage.getItem('access_token') === ''
        ) {
          dispatch({
            type: 'AUTHENTICATE',
            payload: {
              isAuthenticated: false,
              hasDataStore: false,
              user: null,
              dataStore: null,
              idToken,
            },
          });
        } else {
          setIdToken(window.localStorage.getItem('id_token'));
          setLoggedOut(false);
        }
      }),
    []
  );

  const initial = useCallback(async () => {
    if (!cognitoEnabled) {
      dispatch({
        type: 'AUTHENTICATE',
        payload: {
          isAuthenticated: true,
          hasDataStore: false,
          user: null,
          dataStore: null,
          idToken: null,
        },
      });
    } else {
      try {
        await getToken();
      } catch {
        dispatch({
          type: 'AUTHENTICATE',
          payload: {
            isAuthenticated: false,
            hasDataStore: false,
            user: null,
            dataStore: null,
            idToken: null,
          },
        });
      }
    }
  }, [getToken]);

  useEffect(() => {
    initial();
  }, [initial]);

  const login = useCallback(
    () =>
      new Promise(() => {
        window.location.replace(
          `${domain}/oauth2/authorize?response_type=code&scope=email+openid&client_id=${clientId}&redirect_uri=${redirectUri}`
        );
      }),
    [getToken]
  );

  const setDataStore = useCallback(
    (ds) =>
      new Promise(() => {
        dispatch({
          type: 'AUTHENTICATE',
          payload: {
            isAuthenticated: true,
            hasDataStore: true,
            user,
            dataStore: ds,
            idToken,
          },
        });
      }),
    [getToken]
  );

  // same thing here
  const logout = () => {
    setLoggedOut(true);
    window.localStorage.removeItem('refresh_token');
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('id_token');
    window.localStorage.removeItem('lqs_admin_ds');
    window.localStorage.removeItem('lqs_admin_user');
    window.localStorage.removeItem('groups');

    window.location.replace(
      `${domain}/logout?response_type=code&scope=email+openid&client_id=${clientId}&redirect_uri=${redirectUri}`
    );
  };

  const changeDataStore = () => {
    dispatch({
      type: 'AUTHENTICATE',
      payload: {
        isAuthenticated: true,
        hasDataStore: false,
        user,
        dataStore: null,
        idToken,
      },
    });
  };

  const setUserInfo = (user) => {
    if (user.username) {
      window.localStorage.setItem('lqs_admin_user', user.username);
      setUser(user);
    }
  };

  const refreshToken = () => {
    setValidToken(false);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: {
          ...state.user,
        },
        login,
        setUserInfo,
        logout,
        changeDataStore,
        setDataStore,
        refreshToken,
        idToken,
        lqsCache,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
