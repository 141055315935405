import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { OpenAPIProvider } from 'react-openapi-client';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { OPENAPI } from '../config';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';
// components
import LoadingScreen from '../components/LoadingScreen';
import TestAPIToken from '../components/TestAPI';

// ----------------------------------------------------------------------

DSGuard.propTypes = {
  children: PropTypes.node,
};

export default function DSGuard({ children }) {
  const navigate = useNavigate();

  const { setDataStore, dataStore, refreshToken } = useAuth();

  const { pathname } = useLocation();

  const { ds } = useParams();

  const usePlatform = OPENAPI.usePlatform === 'true';

  const [requestedLocation, setRequestedLocation] = useState(null);

  const [dsValid, setDsValid] = useState(false);

  useEffect(() => {
    if (usePlatform) {
      const loadedGroups = window.localStorage.getItem('groups');
      const parsedGroups = loadedGroups ? JSON.parse(loadedGroups) : [];
      if (parsedGroups.length === 1) {
        setDsValid(true);
        if (dataStore !== parsedGroups[0]) {
          setDataStore(parsedGroups[0]);
          if (ds === null || ds === undefined || ds === 'undefined') {
            navigate(PATH_DASHBOARD.log.root(parsedGroups[0]));
          }
        }
      } else if (ds === null || ds === undefined || ds === 'undefined') {
        navigate(PATH_AUTH.dataStore);
      } else {
        setDsValid(true);
        const dsLower = ds.toLowerCase();
        if (dataStore !== dsLower) {
          setDataStore(dsLower);
        }
      }
    } else if (ds !== OPENAPI.defaultDataStore) {
      navigate(PATH_DASHBOARD.root(OPENAPI.defaultDataStore));
    } else {
      setDsValid(true);
    }

    if (requestedLocation && pathname !== requestedLocation) {
      setRequestedLocation(null);
      return <Navigate to={requestedLocation} replace />;
    }
  });

  let definition;
  let serverUrl;
  const protocol = OPENAPI.useHttps === 'true' ? 'https' : 'http';
  if (usePlatform) {
    definition = `${protocol}://${ds}-api.${OPENAPI.baseUrl}/openapi.json`;
    serverUrl = `${protocol}://${ds}-api.${OPENAPI.baseUrl}`;
  } else {
    if (OPENAPI.baseUrl === '/api') {
      definition = `/openapi.json`;
      serverUrl = '';
    } else {
      definition = `${protocol}://${OPENAPI.baseUrl}/openapi.json`;
      serverUrl = `${protocol}://${OPENAPI.baseUrl}`;
    }
  }

  const config = {
    transformRequest: [
      (data, headers) => {
        const token = window.localStorage.getItem('id_token');
        headers.authorization = `Bearer ${token}`;
        headers['Content-Type'] = 'application/json';
        return JSON.stringify(data);
      },
    ],
    validateStatus(status) {
      if (status === 403) {
        refreshToken();
      }

      if (status >= 300) {
        return false;
      }
      return true;
    },
  };

  if (dsValid) {
    return (
      <OpenAPIProvider definition={definition} withServer={{ url: serverUrl }} axiosConfigDefaults={config}>
        <TestAPIToken>
          <>{children}</>
        </TestAPIToken>
      </OpenAPIProvider>
    );
  }
  return <LoadingScreen />;
}
