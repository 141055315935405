import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import useDS from '../hooks/useDS';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  isCollapse: PropTypes.bool,
};

export default function Logo({ disabledLink = false, sx, isCollapse = false }) {
  const ds = useDS();

  if (ds === null) {
    return null;
  }

  const logo = (
    <>
      {isCollapse ? (
        <Box sx={{ width: 40, height: 40, ...sx }}>
          <img src="/logo/logqs_icon.png" alt="LogQS Logo" />
        </Box>
      ) : (
        <Box sx={{ width: 150, height: 40, ...sx }}>
          <img src="/logo/logqs_logo.png" alt="LogQS Logo" />
        </Box>
      )}
    </>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={`/dashboard/${ds}`}>{logo}</RouterLink>;
}
