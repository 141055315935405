// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  dataStore: path(ROOTS_AUTH, '/dataStore'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: (ds) => path(`/dashboard`, `/${ds}`),
  oldUser: {
    root: path(ROOTS_DASHBOARD, '/old-user'),
    new: path(ROOTS_DASHBOARD, '/old-user/new'),
    list: path(ROOTS_DASHBOARD, '/old-user/list'),
    cards: path(ROOTS_DASHBOARD, '/old-user/cards'),
    profile: path(ROOTS_DASHBOARD, '/old-user/profile'),
    account: path(ROOTS_DASHBOARD, '/old-user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/old-user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/old-user/reece-chung/edit`),
  },
  apiKey: {
    root: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/api-key'),
    list: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/api-key/list'),
    new: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/api-key/new'),
    view: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/api-key/${id}`),
    edit: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/api-key/${id}/edit`),
  },
  log: {
    root: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/log'),
    list: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/log/list'),
    new: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/log/new'),
    view: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/log/${id}`),
    edit: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/log/${id}/edit`),
  },
  ingestion: {
    root: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/ingestion'),
    list: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/ingestion/list'),
    new: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/ingestion/new'),
    view: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/ingestion/${id}`),
    edit: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/ingestion/${id}/edit`),
  },
  topic: {
    root: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/topic'),
    list: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/topic/list'),
    new: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/topic/new'),
    view: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/topic/${id}`),
    edit: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/topic/${id}/edit`),
    play: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/topic/${id}/play`),
    listRecord: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/topic/${id}/records`),
    newRecord: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/topic/${id}/records/new`),
    viewRecord: (ds, id, timestamp) => path(`${ROOTS_DASHBOARD}/${ds}`, `/topic/${id}/records/${timestamp}`),
    // editRecord: (id, timestamp) => path(ROOTS_DASHBOARD, `/topic/${id}/records/${timestamp}/edit`),
  },
  record: {
    root: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/record'),
  },
  extraction: {
    root: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/extraction'),
    list: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/extraction/list'),
    new: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/extraction/new'),
    view: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/extraction/${id}`),
    edit: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/extraction/${id}/edit`),
    listTopic: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/extraction/${id}/topics`),
    newTopic: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/extraction/${id}/topics/new`),
    viewTopic: (ds, id, topicId) => path(`${ROOTS_DASHBOARD}/${ds}`, `/extraction/${id}/topics/${topicId}`),
    editTopic: (ds, id, topicId) => path(`${ROOTS_DASHBOARD}/${ds}`, `/extraction/${id}/topics/${topicId}/edit`),
  },
  extractionTopic: {
    root: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/extraction-topic'),
  },
  messageType: {
    root: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/message-type'),
    list: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/message-type/list'),
    new: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/message-type/new'),
    view: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/message-type/${id}`),
    edit: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/message-type/${id}/edit`),
  },
  user: {
    root: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/user'),
    list: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/user/list'),
    new: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/user/new'),
    view: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/user/${id}`),
    edit: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/user/${id}/edit`),
  },
  group: {
    root: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/group'),
    list: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/group/list'),
    new: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/group/new'),
    view: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/group/${id}`),
    edit: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/group/${id}/edit`),
  },
  groupAssociation: {
    root: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/group-association'),
    list: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/group-association/list'),
    new: (ds) => path(`${ROOTS_DASHBOARD}/${ds}`, '/group-association/new'),
    view: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/group-association/${id}`),
    edit: (ds, id) => path(`${ROOTS_DASHBOARD}/${ds}`, `/group-association/${id}/edit`),
  },
};

export const PATH_DOCS = 'https://dev-api.logqs.com/docs';
