import PropTypes from 'prop-types';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Avatar from 'boring-avatars';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, CircularProgress } from '@mui/material';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
  user: PropTypes.object,
};

export default function NavbarAccount({ user, isCollapse }) {
  // const user = useUser();
  const { ds } = useParams();
  return (
    <Link underline="none" color="inherit" component={RouterLink} to={PATH_DASHBOARD.user.view(ds, user?.id)}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <Avatar
          size={40}
          name="admin"
          variant="beam"
          colors={['#E11E25', '#8B8C90', '#353435', '#D2D2D4', '#4E4D4F']}
        />

        {user?.id ? (
          <Box
            sx={{
              ml: 2,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.shorter,
                }),
              ...(isCollapse && {
                ml: 0,
                width: 0,
              }),
            }}
          >
            <Typography variant="subtitle2" noWrap>
              {user?.username}
            </Typography>
            {user?.is_admin && (
              <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                admin
              </Typography>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              ml: 2,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.shorter,
                }),
              ...(isCollapse && {
                ml: 0,
                width: 0,
              }),
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </RootStyle>
    </Link>
  );
}
