import PropTypes from 'prop-types';
import { useOperationMethod } from 'react-openapi-client';
import { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { COGNITO_API } from '../config';

TestAPIToken.propTypes = {
  children: PropTypes.node,
};

export default function TestAPIToken({ children }) {
  const [getMe, { data, error }] = useOperationMethod('Me-get_me');
  const { logout } = useAuth();
  const [failedAttempts, setFailedAttempts] = useState(0);
  const cognitoEnabled = COGNITO_API.cognitoEnabled === 'true';

  useEffect(() => {
    getMe();
  }, [getMe]);

  useEffect(() => {
    if (failedAttempts < 5) {
      setFailedAttempts(failedAttempts + 1);
      getMe();
    } else {
      console.error('Error connecting to Database');
      if (cognitoEnabled) {
        logout();
      }
    }
  }, [error]);

  useEffect(() => {
    setFailedAttempts(0);
  }, [data]);

  return <>{children}</>;
}
