import { OPENAPI } from '../../../config';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`${process.env.PUBLIC_URL}/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),

  log: getIcon('custom/log'),
  ingestion: getIcon('custom/ingestion'),
  topic: getIcon('custom/topic'),
  record: getIcon('custom/record'),
  extraction: getIcon('custom/extraction'),
  extraction_topic: getIcon('custom/extraction_topic'),
  user: getIcon('custom/user'),
  group: getIcon('custom/group'),
  group_association: getIcon('custom/group_association'),
  api_key: getIcon('custom/api_key'),
  message_type: getIcon('custom/message_type'),
  studio: getIcon('custom/studio'),
};

export default function getNavConfig(ds) {
  const hostName = window.location.hostname;
  let domain;
  if (hostName === 'admin.logqs-dev.com' || OPENAPI.baseUrl === 'logqs-dev.com') {
    domain = 'studio.logqs-dev.com';
  } else {
    domain = 'studio.logqs.com';
  }

  const usePlatform = OPENAPI.usePlatform === 'true';

  let serverUrl;
  const protocol = OPENAPI.useHttps === 'true' ? 'https' : 'http';
  if (usePlatform) {
    serverUrl = `${protocol}%3A%2F%2F${ds}-api.${OPENAPI.baseUrl}`;
  } else {
    if (OPENAPI.baseUrl === '/api') {
      serverUrl = '';
    } else {
      serverUrl = `${protocol}%3A%2F%2F${OPENAPI.baseUrl}`;
    }
  }

  const navConfig = [
    // GENERAL
    // items: [{ title: 'studio', path: `https://${domain}/player?url=${serverUrl}`, icon: ICONS.studio }],

    // ----------------------------------------------------------------------
    {
      subheader: 'LQS',
      items: [
        { title: 'log', path: PATH_DASHBOARD.log.root(ds), icon: ICONS.log },
        { title: 'ingestions', path: PATH_DASHBOARD.ingestion.list(ds), icon: ICONS.ingestion },
        { title: 'topics', path: `${PATH_DASHBOARD.topic.list(ds)}`, icon: ICONS.topic },
        { title: 'records', path: PATH_DASHBOARD.record.root(ds), icon: ICONS.record },
        { title: 'extractions', path: PATH_DASHBOARD.extraction.list(ds), icon: ICONS.extraction },
        { title: 'extraction topics', path: PATH_DASHBOARD.extractionTopic.root(ds), icon: ICONS.extraction_topic },
      ],
    },
    {
      subheader: 'group',
      items: [
        { title: 'users', path: PATH_DASHBOARD.user.list(ds), icon: ICONS.user },
        { title: 'groups', path: PATH_DASHBOARD.group.list(ds), icon: ICONS.group },
        { title: 'group associations', path: PATH_DASHBOARD.groupAssociation.list(ds), icon: ICONS.group_association },
        { title: 'api keys', path: PATH_DASHBOARD.apiKey.root(ds), icon: ICONS.api_key },
        { title: 'message types', path: PATH_DASHBOARD.messageType.list(ds), icon: ICONS.message_type },
      ],
    },
    {
      subheader: 'apps',
      items: [{ title: 'studio', path: `https://${domain}`, icon: ICONS.studio }],
    },
  ];

  return navConfig;
}
